import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { doc, setDoc, getDoc, getFirestore } from "firebase/firestore"; 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAFCO2oiv8jNpIDN6ILjFnRMc1qBJPPXhw",
  authDomain: "namishshortener.firebaseapp.com",
  projectId: "namishshortener",
  storageBucket: "namishshortener.appspot.com",
  messagingSenderId: "453337518472",
  appId: "1:453337518472:web:8cc514da8a445586096a3d",
  measurementId: "G-H97QLZTLH7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById('root'));
document.title = "NamishShortener";
var urlParams = new URL(window.location.href).searchParams;
if(urlParams.get("t") === "s") {
    async function doIT() {
      const tnum = urlParams.get("id");
    const docRef = await doc(getFirestore(), "URLS", "URLS");
    const docSnap = getDoc(docRef);
    const mainDat = (await docSnap).data();
    window.location.href = mainDat[tnum];
    }
    doIT();
}else{
root.render(
  <React.StrictMode style={{textAlign:"center"}}>
    <div style={{backgroundColor:"black", textAlign:"center", color:"white", padding:"30px", fontSize:"20px"}}>
      <h1>NamishShortener</h1>
      <p>Shorten every URL or link in just one click</p>
      <form onSubmit={function (e) {
        e.preventDefault();
        mainShort();
      }}>
      <button>Try it now</button>
      </form>
    </div>
    <div style={{textAlign:"center", fontSize:"20px"}}>
    <h1>How does it work?</h1>
    <p>NamishShortener just asks for your target URL and creates a simple URL that points to your target URL. The generated URL is completely unique and consists of a simple token that no one can even guess. So, If you are interested in using NamishShortener, don't wait and try it now!</p>
    <br></br>
    <hr></hr>
    <br></br>
    <h1>Created by <i>Namish Kumar</i>, a student of Class 8</h1>
    </div>
  </React.StrictMode>
);
}
function mainShort() {
  document.documentElement.style = `
    display:table;
    height:100%;
    width:100%;
    text-align:center;
  `;
  document.body.style = `
    display:table-cell;
    vertical-align:middle; 
    width:100%;
    text-align:center;
  `;
   root.render (
     <div style={{backgroundColor:"black", color:"white", fontSize:"20px", marginLeft:"15px", marginRight:"15px", borderRadius:"5px", padding:"100px",}} id="cont">
       <p>Please enter the target URL with HTTP or HTTPS</p>
       <form onSubmit={function(e){
        e.preventDefault();
        const targetURL = document.getElementById("stub").value;
        generateURL(targetURL);
       }}>
       <input id="stub" style={{backgroundColor:"white", width:"100%"}} placeholder="Target URL"></input>
       <br></br>
       <br></br>
       <button type='submit' style={{width:"100%"}}>Generate</button>
       </form>
     </div>
   );
   
}
function generateURL(targetURL) {
  document.body.innerHTML = `
  <noscript>You need to enable JavaScript to run this app.</noscript>
  `;
  const progressCont = document.createElement("div");
  progressCont.style = `
  width: 100%;
  background-color: grey;
  `;
  const mainCont = document.body.appendChild(progressCont);
  const progressBar = document.createElement("div");
  progressBar.style = `
  width: 1%;
  height: 30px;
  background-color: cadetblue;
  transition: 0.5s;
  `;
  const mainProg = mainCont.appendChild(progressBar);
  setTimeout(function(){ 
    mainProg.style.width = "20%";
    const db = getFirestore();
    const suffixer = generateSufix();
    const cityRef = doc(db, 'URLS', 'URLS');
setDoc(cityRef, { [suffixer]: targetURL}, { merge: true });
    setTimeout(function(){ 
      mainProg.style.width = "40%";
      setTimeout(function(){ 
        mainProg.style.width = "60%";
        setTimeout(function(){ 
          mainProg.style.width = "80%";
          setTimeout(function(){ 
            mainProg.style.width = "100%";
            showNow(suffixer);
         },500);
       },500);
     },500);
   },500);
 },500);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
function generateSufix() {
  return Math.floor(Math.random() * 1000000000001);
}
function showNow(suffixer) {
  document.body.innerHTML = `
  <noscript>You need to enable JavaScript to run this app.</noscript>
  <div id="root"></div>
  `;
  const shURL = "https://nus.namishkumar.in/?t=s&id=" + suffixer;
  document.getElementById("root").innerHTML = `
  <p style="text-align:center; font-size:20px">The Shortened URL is <a href="${shURL}">${shURL}</a></p>
  `;
}

